/**************************************

common.scss

***************************************/
/* ---------------------------------------------------------
大枠のレイアウト
----------------------------------------------------------*/
/*--  --*/
#mainContainer{
	color: $fontColorMain;
}

.container{
	width: 960px;
	margin: 0 auto;
	position: relative;
	@include media($notepcSize){
		width: 87.5%;
	}
	@include media($mobileSize){
		width: 87.5%;
	}
}

//line hoverアニメーション
.c-line {
    position: relative;
    display: inline-block;
    font-size: 1em;
    text-decoration-line: none;
}

.c-line::before {
    position: absolute;
    top: 1.8em;
	left: 0;
	right: 0;
    content: "";
    display: inline-block;
    width: 0;
    height: 2px;
    background: #fff;
	transition: 0.5s;
	margin: 0 auto;
}

.c-line:hover::before {
    width: 85%;
    @include media($pcSize_s){
      display: none;
    }
}
