/**************************************

footer.scss

***************************************/
.footer{
    width: 100%;
    position: fixed;
    left: 3%;
    right: 0;
    bottom: 3%;
    margin: 0 auto;
    // z-index: 2;
    @include media($mobileSize){
        left: 0;
        text-align: center;
    }
    .footer__copy {
        @include fontsize(10);
        color: #fff;
    }
}
/* ---------------------------------------------------------

----------------------------------------------------------*/

/*--  --*/