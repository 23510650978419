/**************************************

variables.scss

***************************************/
/* ---------------------------------------------------------
utility用
----------------------------------------------------------*/
$base-size: 8;

/* ---------------------------------------------------------
break point
----------------------------------------------------------*/
$wideSize: 1400px;
$pcSize: 1280px;
$pcSize_s: 1024px;
$notepcSize: 960px;
$tabletSize: 768px;
$mobileSize: 640px;
$mobileSize_iPhone: 480px;
$mobileSize_s: 400px;
/*$wideSize: 1400px;
$pcSize: 1278px;
$notepcSize: 1080px;
$tabletSize: 768px;
$mobileSize: 640px;*/

/* ---------------------------------------------------------
color
----------------------------------------------------------*/
$bgColorMain: #ffffff;
$bgColorSub: #E5E5E5;
$fontColorMain: #231815;
$fontColorSub: #ffffff;
$fontColorBtnSub: #727171;
// $fontColorHeading: #000000;
// $fontColorHeading2: #4D4D4D;
// $fontColorHeading3: #C9145A;
// $headerBaseColor: rgba(168,168,168,0.8);
// $headerSubColor: rgba(255,255,255,0.8);
// $footerBaseColor: #E7E7E7;
$accentColor01: #AD300E;
$accentColor02: #B08E39;


/* ---------------------------------------------------------
font family
----------------------------------------------------------*/
$fontFamilyBase: '小塚ゴシック Pro','Kozuka Gothic Pro','ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS P Gothic', sans-serif;
// $fontFamilyBase: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS P Gothic', sans-serif;
$fontFamilyHeading: "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
$fontFamilyGoth: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS P Gothic', sans-serif;
$fontFamilyMin: "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
