/**************************************

header.scss

***************************************/
.header{
    width: 100%;
    height: 60px;
    position: fixed;
    left: 3%;
    right: 0;
    top: 3%;
    bottom: 0;
    margin: 0 auto;
    @include flex();
    @include justify();
    // z-index: 2;
    .header__logo {
        width: 100%;
        max-width: 334px;
        @include media($pcSize_s){
            width: 73%;
        } 
        // @include media($mobileSize){
        //     width: 50%;
        // }
        a {
            &:hover {
                @include opa();
            }
        }
    }
    .header__nav {
        width: 534px;
        position: absolute;
        right: 6%;
        // top: 2%; 
        margin-top: 23px;
        @include media($pcSize_s){
            display: block;
            width: 50%;
            height: 100%;
            position: fixed;
            top: 0;
            right: -600px;
            transition: .5s;
            background-color: rgba(255, 255, 255, .8);
            box-sizing: border-box;
            margin-top: 0;
        }
        .header__navTeams {
            @include flex();
            @include justify();
            @include media($pcSize_s){
                display: block;
            }
            .header__navTeam {
                width: 100%;
                border-left: 1px solid #fff;
                border-right: 1px solid #fff;
                box-sizing: border-box;
                @include media($pcSize_s){
                    border: none;
                }    
                a {
                    width: 100%;
                    display: block;
                    color: #fff;
                    @include fontsize(16);
                    padding: 10px 0;
                    box-sizing: border-box;
                    text-align: center;
                    @include media($pcSize_s){
                        text-align: right;
                        color: #5D5D5D;
                        padding: 20px 0;
                        padding-right: 30px;
                        @include fontsize(20);
                    }
                }
                &:last-child {
                }
            }
            .header__navTeam--sp {
                display: none;
                @include media($pcSize_s){
                    display: block;
                }
            } 
        } 
        .header__hamburger--close {
            display: none;
            @include media($pcSize_s){
            display: block;
            transition: all .4s;
            box-sizing: border-box;
            position: relative;
            width: 50px;
            height: 44px;
            margin-top: 35px;
            margin-bottom: 55px;
            margin-left: auto;
            margin-right: 30px;
            span {
                display: inline-block;
                transition: all .4s;
                box-sizing: border-box;
                position: absolute;
                left: 0;
                width: 100%;
                height: 4px;
                background-color: #5D5D5D;
                &:nth-of-type(1) {
                -webkit-transform: translateY(20px) rotate(-45deg);
                transform: translateY(20px) rotate(-45deg);
                top: 0;
                }
                &:nth-of-type(2) {
                    opacity: 0;                
                } 
                &:nth-of-type(3) {
                    -webkit-transform: translateY(-20px) rotate(45deg);
                    transform: translateY(-20px) rotate(45deg);
                    bottom: 0;             
                    }       
                }
            } 
            @include media($mobileSize){
                margin-top: 24px;
                margin-right: 10px;
            }
        } 
    }
    .header__hamburger {
        display: none;
        @include media($pcSize_s){
        display: inline-block;
        transition: all .4s;
        box-sizing: border-box;
        position: relative;
        width: 50px;
        height: 44px;
        right: 8%;
        bottom: -13px;
        @include media($mobileSize){
            // bottom: -2px;
        }
        @include media($mobileSize_s){
            bottom: -2px;
        }
        span {
            display: inline-block;
            transition: all .4s;
            box-sizing: border-box;
            position: absolute;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: #fff;
                &:nth-of-type(1) {
                    top: 0;
                }
                &:nth-of-type(2) {
                    top: 20px;
                }
                &:nth-of-type(3) {
                    bottom: 0;
                }
            }
        }
    } 
}


/* ---------------------------------------------------------

----------------------------------------------------------*/
/*--  --*/
