/**************************************

main.scss

***************************************/
/* ---------------------------------------------------------
foundation
----------------------------------------------------------*/
@import "foundation/reset";
@import "foundation/webfont";
@import "foundation/variables";
@import "foundation/mixins";
@import "foundation/base";
@import "foundation/function";
/* ---------------------------------------------------------
layout
----------------------------------------------------------*/
@import "layout/common";
@import "layout/header";
@import "layout/footer";
@import "layout/main";

/* ---------------------------------------------------------
object
----------------------------------------------------------*/
/*-- component --*/
// @import "object/component/component";
/*-- project --*/

/*-- utility --*/
// @import "object/utility/vertical-align";
// @import "object/utility/border";
// @import "object/utility/clearfix";
// @import "object/utility/display";
// @import "object/utility/float";
// @import "object/utility/font-size";
// @import "object/utility/line-clamp";
// @import "object/utility/margin";
// @import "object/utility/misc";
// @import "object/utility/padding";
// @import "object/utility/position";
// @import "object/utility/text-align";
// @import "object/utility/text-decoration";
// @import "object/utility/text-truncate";
// @import "object/utility/width";

/* ---------------------------------------------------------
pages
----------------------------------------------------------*/
// @import "pages/top";
