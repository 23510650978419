/**************************************

main.scss

***************************************/
/* ---------------------------------------------------------
レイアウト
----------------------------------------------------------*/


// 各セクションのヒーロー画像のレイアウト

.section {
    width: 100%;
  }

// 大枠のレイアウト//

// 大枠のレイアウト section__first//
.section__first {
      width: 100%;
      background-image: url(../img/section_pic1.jpg);
      height: 100vh;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
      @include flex();
      @include content();
      @include column();
      .section__content {
        width: 100%;
        max-width: 550px;
        margin: 80px auto 0;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        text-align: center; 
        color: #fff;
        // position: relative;
        @include media($notepcSize){
            width: 90%;
        }
        .section__contentTit {
            .section__contentTitJa {
                @include fontsize (60);
                letter-spacing: 10px;
                font-family: a-otf-gothic-bbb-pr6n, sans-serif;
                font-weight: 600;
                font-style: normal;
                margin-bottom: 5%;
                @include media($notepcSize){
                    @include fontsize (40);
                }
                @include media($mobileSize){
                    @include fontsize (25);
                }
                span {
                    @include fontsize (70);
                    font-weight: 300;
                    font-style: normal;
                    font-family: a-otf-ryumin-pr6n, serif;
                    letter-spacing: 10px;
                    @include media($notepcSize){
                        @include fontsize (50);
                    }
                    @include media($mobileSize){
                        @include fontsize (35);
                    }    
                }
                small {
                    @include fontsize (55);
                    font-family: a-otf-ryumin-pr6n, serif;
                    font-weight: 300;
                    font-style: normal;
                    letter-spacing: 15px;
                    @include media($notepcSize){
                        @include fontsize (35);
                    } 
                    @include media($mobileSize){
                        @include fontsize (20);
                    }       
                }
            }
            .section__contentTitEn {
                font-family: 'M PLUS 1p', sans-serif;
                @include fontsize (33);
                font-style: initial;
                letter-spacing: 5px;
                margin-bottom: 5%;
                @include media($notepcSize){
                    @include fontsize (23);
                }
                @include media($mobileSize){
                    @include fontsize (16);
                }    
            }
        }
        .section__contentCopy {
            @include fontsize (16);
            line-height: 2.2;
            margin-bottom: 15%;
            @include media($notepcSize){
                @include fontsize (14);
            }
        }
        .section__contentBtn {
            width: 176px;
            margin: 0 auto 50px;
            @include media($mobileSize){
                width: 130px;
                margin: 0 auto 25px;
            }
            a {
                width: 100%;
                display: inline-block;
                line-height: 2.5;
                border: 1px solid #fff;
                color: #fff;
                overflow: hidden;
                position: relative;
                z-index: 2;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                -webkit-transition: all .5s;
                transition: all .5s;
                &::before {
                    content: "";
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    left: -100%;
                    -webkit-transition: all .5s;
                    transition: all .5s;
                    z-index: -1;
                }
                &:hover {
                    &::before {
                        left: 100%;
                        background-color: rgba(255, 255, 255, .5);
                    }
                }
            }
        }
        .section__contentArrow {
            width: 55px;
            margin: 0 auto;
            @include media($mobileSize){
                width: 25px;
            }
            &:hover {
                @include opa();
            }
        }
    }
  }

// 大枠のレイアウト section__second//
.section__second {
    width: 100%;
    background-image: url(../img/section_pic2.jpg);
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    @include flex();
    @include content();
    @include column(); 
    .section__content {
        // width: 100%;
        // max-width: 550px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        text-align: center; 
        color: #fff;
        .section__secondInner {
            width: 100%;
            max-width: 864px;
            margin: 80px auto 80px;
            text-align: left;
            @include flex();
            @include justify();
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            text-align: center;     
            @include media($notepcSize){
                width: 90%;
            }
            @include media($mobileSize){
                @include column();
                align-items: end;
                margin: 80px auto 25px;
            }
            .section__contentTit {
                font-weight: bold;
                font-style: normal; 
                @include fontsize (70);
                letter-spacing: 7px;
                @include media($notepcSize){
                    @include fontsize (50);
                }
                @include media($mobileSize){
                    @include fontsize (30);
                    margin-bottom: 30px;
                }
            } 
            .section__secondDetail {
                text-align: left;
                @include media($mobileSize){
                    margin-left: auto;
                    width: 93%;
                }
                .section__secondDetailTit {
                    @include fontsize (17);
                    border: 1px solid #fff;
                    padding: 3px;
                    box-sizing: border-box;
                    margin-bottom: 5%;
                    text-align: center;
                    letter-spacing: 3px;
                    @include media($notepcSize){
                        @include fontsize (14);
                    }
                    @include media($mobileSize){
                        @include fontsize (12);
                    }
                }
                .section__secondDetailTxt {
                    @include fontsize (14);
                    margin-bottom: 10%;
                    line-height: 1.5;
                    @include media($notepcSize){
                        @include fontsize (12);
                    }
                }
                .section__secondTeams {
                    .section__secondTeam {
                        @include fontsize (17);
                        margin-bottom: 20px;
                        font-weight: bold;
                        @include media($notepcSize){
                            @include fontsize (14);
                        }
                        @include media($mobileSize){
                            margin-bottom: 12px;
                        }  
                    }
                }
            } 
        }
        .section__contentBtn {
            width: 176px;
            margin: 0 auto 50px;
            @include media($mobileSize){
                width: 130px;
                margin: 0 auto 25px;
            }
            a {
                width: 100%;
                display: inline-block;
                line-height: 2.5;
                border: 1px solid #fff;
                color: #fff;
                overflow: hidden;
                position: relative;
                z-index: 2;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                -webkit-transition: all .5s;
                transition: all .5s;
                &::before {
                    content: "";
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    left: -100%;
                    -webkit-transition: all .5s;
                    transition: all .5s;
                    z-index: -1;
                }
                &:hover {
                    &::before {
                        left: 100%;
                        background-color: rgba(255, 255, 255, .5);
                    }
                }
            }
        }
        .section__contentArrow {
            width: 55px;
            margin: 0 auto;
            @include media($mobileSize){
                width: 25px;
            }
            &:hover {
                @include opa();
            }
        }
    }
}

// 大枠のレイアウト section__third//
.section__third {
    width: 100%;
    background-image: url(../img/section_pic3.jpg);
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    @include flex();
    @include content();
    @include column(); 
    .section__content {
        width: 100%;
        max-width: 700px;
        margin: 80px auto 0;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        text-align: center; 
        color: #fff;
        @include media($mobileSize_s){
            margin: 40px auto 0;
        }
        .section__contentTit {
            font-weight: bold;
            font-style: normal; 
            @include fontsize (70);
            letter-spacing: 7px;
            margin-bottom: 30px;
            @include media($notepcSize){
                @include fontsize (50);
            }
            @include media($mobileSize){
                @include fontsize (30);
            }
            @include media($mobileSize_s){
                margin-bottom: 17px;
            }
        } 
        .section__contentCopy {
            @include fontsize (16);
            font-weight: bold;
            line-height: 2.2;
            margin-bottom: 10px;
            @include media($notepcSize){
                @include fontsize (14);
            }
            @include media($mobileSize){
                display: none;
            }
        }
        .section__thirdInner {
            margin-bottom: 25px;
            @include media($mobileSize){
                margin-bottom: 20px;
            }
            .section__thirdList {
                @include flex();
                @include fontsize (14);
                @include media($notepcSize){
                    @include fontsize (12);
                }
                .section__thirdTit {
                    text-align: right;
                    width: 40%;
                    border-right: 1px solid #fff;
                    padding-right: 12px;
                    padding-bottom: 8px;
                    box-sizing: border-box;
                    line-height: 1.5;
                    margin-left: auto;
                    @include media($mobileSize){
                        width: 25%;
                        margin-left: 0;
                        padding-bottom: 6px;    
                    }
                }
                .section__thirdDetail {
                    text-align: left;
                    width: 50%;
                    padding-left: 12px;
                    padding-bottom: 8px;
                    box-sizing: border-box;
                    line-height: 1.2;
                    span {
                        margin-top: 10px;
                        a {
                          width: 130px;
                          display: block;
                          border: 1px solid #fff;
                          border-radius: 15px;
                          color: #fff;
                          @include fontsize (10);
                          padding: 3px 2px;
                          box-sizing: border-box;
                          text-align: center;
                          margin-top: 5px;
                        }   
                    }
                }
                &:first-child {
                    .section__thirdTit {
                        padding-top: 5px;
                    }
                    .section__thirdDetail {
                        padding-top: 5px;
                    }     
                }
            }
        }
        .section__contentBtn {
            width: 176px;
            margin: 0 auto 30px;
            @include media($mobileSize){
                width: 130px;
                margin: 0 auto 20px;
            }
            a {
                width: 100%;
                display: inline-block;
                line-height: 2.5;
                border: 1px solid #fff;
                color: #fff;
                overflow: hidden;
                position: relative;
                z-index: 2;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                -webkit-transition: all .5s;
                transition: all .5s;
                &::before {
                    content: "";
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    left: -100%;
                    -webkit-transition: all .5s;
                    transition: all .5s;
                    z-index: -1;
                }
                &:hover {
                    &::before {
                        left: 100%;
                        background-color: rgba(255, 255, 255, .5);
                    }
                }
            }
        }
        .section__contentArrow {
            width: 55px;
            margin: 0 auto;
            @include media($mobileSize){
                width: 25px;
            }
            &:hover {
                @include opa();
            }
        }
    }
}

// 大枠のレイアウト section__four//
.section__four {
    width: 100%;
    background-image: url(../img/section_pic4.jpg);
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    @include flex();
    @include content();
    @include column(); 
    .section__content {
        // width: 100%;
        // max-width: 550px;
        margin: 0 auto;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        text-align: center; 
        color: #fff;
    }
}







/*-- 状態変化など --*/